import React from 'react';
import { isHauptadmin } from '../lib/globalData';
import { useRecordContext, ListBase, Datagrid, useTranslate, TextField, DateField } from 'react-admin';
import NoPagination from '../lib/NoPagination';

const DateDField = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <div style={{ minWidth: '5em'}}>
      {record.startdatum}
    </div>
  )
}

const InfoText = () => {
  const translate = useTranslate();  
  const record = useRecordContext();
  if (!record) return null;
  var children = []
  console.log(record)
  if(record.children && record.children.length > 0){
    for (var i = 0; i < record.children.length; i++) {
      children.push(
        <div>
          <br />
          <b>{record.children[i].startdatum} Update:</b>
          <br />
          <div dangerouslySetInnerHTML={{__html: record.children[i].text}} />
        </div>
      )
    }
  }
  return (
    <div>
      { record.enddatum !== "0000-00-00 00:00:00"
 &&
        <b style={{color: '#00BB00'}}>{translate('bw.dashboard.incident_archive.incident')}: {translate('bw.dashboard.incident_archive.resolved')}{record.enddatum}</b>
      }
      { record.enddatum === "0000-00-00 00:00:00"
 &&
        <b style={{color: '#E52324'}}>{translate('bw.dashboard.incident_archive.incident')}: </b>
      }
      <div dangerouslySetInnerHTML={{__html: record.text}} />
      {children}
    </div>
  )
}

const GridHeader = () => {
  const translate = useTranslate();  
  return (
    <thead>
      <tr>
        <th colSpan={2} style={{textAlign: 'left', paddingTop: '1em'}}>
          {translate('bw.dashboard.incident_archive.title')}
        </th>
      </tr>
    </thead>
  )
}

const Ereignisarchiv = (props) => (
    <ListBase resource="dashboardHomeinfo" title="bw.set.agecategory.title" filter={{ typ: 2 }}
      exporter={false} pagination={<NoPagination />} perPage={1000} >
      <Datagrid header={<GridHeader />} style={{ width: 'auto' }} bulkActionButtons={false} sx={{'& .MuiTableCell-root': {verticalAlign: 'top'}}}>
        <DateDField sx={{verticalAlign: 'top'}} />
        <InfoText />
{isHauptadmin() &&
        <TextField source="id" textAlign="right" label="" />
}
      </Datagrid>
    </ListBase>
  )

export default Ereignisarchiv